import getMuiTheme from "material-ui/styles/getMuiTheme";

export const theme = getMuiTheme({
    palette: {
        primary1Color: '#2b476e'
    },
});

export const StyledTheme = {
    colors: {
        turquoise: "#01b2cb",
        lightGray: "#a7a29e",
        white: "#ffffff",
        black: "#000000",
        charcoal: "#333333",
        textDarkBlue: "#00678b",
        paper: "#f7f7f7",
        textBlue: "#176e93",
        purple: "#866ca3",
        red: "#c5002e",
        pink: "#d4c0c7",
        link: "#74abb7",
        linkActive: "#866ca3",

        // 2023
        darkGray: "#aaa097",
        lightGreen: "#c0c6a2",
        lightPink: "#f6a7a0",
        lightPurple: "#c48da0",
        lightYellow: "#fed273",
        lightBlue: "#5dc8dc",
        darkBlue: "#2b476e",

        // 2024
        background: "#100036",
        altBackground: "#1d0064",
        first2024: '#f6aa2b',
        second2024: '#7d87af',
        third2024: '#ff9f99',
        fourth2024: '#bf7aa3',
        fifth2024: '#62b7a7',
        sixth2024: '#ff8800',
        seventh2024: '#ffc800',
        eighth2024: '#f86535',
        ninth2024: "#a88f89",
        tenth2024: "#7ca25d"
    }
}

export const size = {
    mobileS: "320px",
    mobileM: "375px",
    mobileL: "425px",
    tablet: "768px",
    laptop: "1024px",
    laptopL: "1440px",
    desktop: "2560px",
};

export const device = {
    mobileS: `screen and (min-width: ${size.mobileS})`,
    mobileM: `screen and (min-width: ${size.mobileM})`,
    mobileL: `screen and (min-width: ${size.mobileL})`,
    tablet: `screen and (min-width: ${size.tablet})`,
    laptop: `screen and (min-width: ${size.laptop})`,
    laptopL: `screen and (min-width: ${size.laptopL})`,
    desktop: `screen and (min-width: ${size.desktop})`,
    desktopL: `screen and (min-width: ${size.desktop})`,
};
