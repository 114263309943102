/**
 * Created by Filip Drgoň on 16/04/17.
 */
import React, {Component} from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import json2csv from 'json2csv';
import fileDownload from 'js-file-download';
import {Bar} from 'react-chartjs-2';
import {format, isAfter, isBefore, parse} from "date-fns";

import {
    authors2018,
    authors2019,
    authors2020,
    authors2021,
    authors2022,
    authors2023,
    authors2024
} from "../utils/authors";
import {auth, database} from "../index";

export default class AdminComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            email: "",
            password: "",
            invalidEmail: false,
            invalidPassword: false,
            tableData: [],
            loading: true,
            entries: 0,
            winner: {
                id: 1,
                name: "Loading . . .",
                votes: 0,
            },
            bookField: this.bookFieldBuilder(authors2024),
            selectedYear: 2024,
            endDate: new Date(),
        };
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({loggedIn: true});
                this.getFromServer();
            } else {
                this.setState({loggedIn: false});
            }
        });
    }

    bookFieldBuilder = authors => authors.map((author, index) => ({
        id: index + 1,
        name: `${author.name}: ${author.book}`,
        votes: 0
    }));

    getFromServer() {
        database.ref(`voters${this.state.selectedYear}`).once('value', (snapshot) => {
            if (snapshot.exists()) {
                let voteCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                let entries = 0;
                let data = Object.values(snapshot.toJSON()).map(elem => {
                    voteCount[elem.selected - 1]++;
                    entries++;
                    return ({...elem, newsletter: elem.newsletter ? "Áno" : "Nie"});
                });
                this.setState({tableData: data, loading: false});
                let bookField = this.state.bookField.map(elem => ({...elem, votes: voteCount[elem.id - 1]}));
                let winner = bookField.reduce((l, e) => e.votes > l.votes ? e : l);
                this.setState({
                    bookField: bookField,
                    entries: entries,
                    winner: winner
                });
            } else {
                this.setState({loading: false});
            }
        }, (err) => {
            console.error(err.message);
        });
        database.ref('settings/endDate').once('value', (snapshot) => {
            if (snapshot.exists()) {
                this.setState({endDate: new Date(snapshot.val())});
            }
        }, (err) => {
            console.error(err.message);
        });
    }

    exportDB() {
        let fields = ['name', 'email', 'selected', 'newsletter', 'time'];
        let csv = json2csv.parse(this.state.tableData, {fields: fields, excelStrings: true, withBOM: true});
        fileDownload(csv, `Anasoft litera ${this.state.selectedYear}.csv`);
    }

    setDate = () => {
        const datePicker = document.getElementById('datePicker');
        const timePicker = document.getElementById('timePicker');
        let date = new Date(datePicker.value + ' ' + timePicker.value);
        this.setState({endDate: date});
    }

    render() {
        const columns = [
            {
                Header: "Meno",
                accessor: "name",
            },
            {
                Header: "Priezvisko",
                accessor: "surname",
            },
            {
                Header: "E-mail",
                accessor: "email",
            },
            {
                Header: "Hlas",
                accessor: "selected",
            },
            {
                Header: "Newsletter",
                accessor: "newsletter",
            },
            {
                Header: "Čas",
                accessor: "time",
                sortMethod: (a, b) => {
                    let aDate = parse(a, 'dd.MM.yyyy HH:mm:ss', new Date())
                    let bDate = parse(b, 'dd.MM.yyyy HH:mm:ss', new Date())
                    if (isBefore(aDate, bDate)) {
                        return -1;
                    } else if (isAfter(aDate, bDate)) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            },
        ];
        const selectedAuthors = selectAuthors(this.state.selectedYear)
        let data = {
            labels: selectedAuthors.map(author =>
                author.name
            ),
            datasets: [
                {
                    label: "Počet hlasov",
                    backgroundColor: '#176e9355',
                    borderColor: "#176e93",
                    borderWidth: 1,
                    data: this.state.bookField.map(a => a.votes)
                }
            ]
        };
        return (
            <div className="root admin">
                {this.state.loggedIn ?
                    <div className="logged-in">
                        <div className="admin-block">
                            <div className="header-block">
                                <div className={"stats-block" + (this.state.loading ? " loading" : "")}>
                                    {
                                        this.state.loading
                                            ? <i className="fa fa-spin fa-spinner"/>
                                            : <Bar data={data} width={650} options={{maintainAspectRatio: false}}/>
                                    }
                                </div>
                                <div className="logout-block">
                                    <div className="logout-block-top">
                                        <RaisedButton
                                            label="Odhlásiť Sa"
                                            primary={true}
                                            onClick={() => auth.signOut()}
                                        />
                                    </div>
                                    <div className="logout-block-year">
                                        <SelectField
                                            fullWidth
                                            floatingLabelText="Ročník"
                                            underlineStyle={{borderColor: "rgba(0, 0, 0, 0.298039)"}}
                                            value={this.state.selectedYear}
                                            onChange={(e, i, v) => this.setState({
                                                selectedYear: v,
                                                bookField: this.bookFieldBuilder(selectAuthors(v)),
                                                loading: true
                                            }, () => this.getFromServer())}
                                            selectedMenuItemStyle={{color: "#176e93"}}
                                        >
                                            <MenuItem value={2018} primaryText={2018}/>
                                            <MenuItem value={2019} primaryText={2019}/>
                                            <MenuItem value={2020} primaryText={2020}/>
                                            <MenuItem value={2021} primaryText={2021}/>
                                            <MenuItem value={2022} primaryText={2022}/>
                                            <MenuItem value={2023} primaryText={2023}/>
                                            <MenuItem value={2024} primaryText={2024}/>
                                        </SelectField>
                                        <RaisedButton
                                            label="Export dát"
                                            primary={true}
                                            className="export-button"
                                            onClick={() => this.exportDB()}
                                        />
                                    </div>
                                    <div className="logout-block-date">
                                        <label>Ukončenie hlasovania:</label>
                                        <input type="date"
                                               id="datePicker"
                                               name="voting-end-date"
                                               value={format(this.state.endDate, "yyyy-MM-dd")}
                                               onChange={this.setDate}
                                               min={new Date()}/>
                                        <input type="time"
                                               id="timePicker"
                                               name="voting-end-time"
                                               value={format(this.state.endDate, "HH:mm")}
                                               onChange={this.setDate}
                                        />
                                        <RaisedButton
                                            label="Uložiť"
                                            primary={true}
                                            onClick={() => database.ref('settings/endDate').set(this.state.endDate.toISOString())}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="winner-block">
                                Aktuálne
                                vyhráva: <b>{this.state.winner.name}</b> s <b>{this.state.winner.votes}</b> hlasmi!<br/>
                                Celkovo zahlasovalo <b>{this.state.entries}</b> hlasujúcich.
                            </div>
                            <div className="table-block">
                                <ReactTable
                                    columns={columns}
                                    data={this.state.tableData}
                                    loading={this.state.loading}
                                    defaultPageSize={10}
                                    className='-striped -highlight'
                                />
                            </div>
                        </div>
                    </div>
                    :
                    <div className="logged-out">
                        <div className="login-block">
                            <div className="login-email">
                                <div className="field">
                                    <TextField fullWidth={true}
                                               underlineStyle={{
                                                   borderColor: "rgba(0, 0, 0, 0.298039)"
                                               }}
                                               className="text-field"
                                               hintText="admin@email.com"
                                               floatingLabelText="E-mail"
                                               onChange={(e, v) => {
                                                   this.setState({email: v, wrongEmail: false});
                                               }}
                                               errorText={this.state.wrongEmail ? "Wrong e-mail!" : null}
                                    />
                                </div>
                            </div>
                            <div className="login-password">
                                <div className="field">
                                    <TextField fullWidth={true}
                                               underlineStyle={{
                                                   borderColor: "rgba(0, 0, 0, 0.298039)"
                                               }}
                                               className="text-field"
                                               hintText="Apple123"
                                               floatingLabelText="Password"
                                               onChange={(e, v) => {
                                                   this.setState({password: v, wrongPassword: false});
                                               }}
                                               type={"password"}
                                               errorText={this.state.wrongPassword ? "Wrong password!" : null}
                                    />
                                </div>
                            </div>
                            <div className="login-button">
                                <RaisedButton label="Prihlásiť sa" primary={true} style={{marginTop: "30px"}}
                                              onClick={() => {
                                                  auth.signInWithEmailAndPassword(this.state.email, this.state.password).catch((e) => {
                                                      if (e.code === "auth/user-not-found") {
                                                          this.setState({wrongEmail: true});
                                                      }
                                                      if (e.code === "auth/wrong-password") {
                                                          this.setState({wrongPassword: true});
                                                      }
                                                  })
                                              }}/>
                            </div>
                        </div>
                    </div>}
            </div>
        )
    }
}

const selectAuthors = (year) => {
    switch (year) {
        case 2018:
            return authors2018;
        case 2019:
            return authors2019;
        case 2020:
            return authors2020;
        case 2021:
            return authors2021;
        case 2022:
            return authors2022;
        case 2023:
            return authors2023;
        case 2024:
        default:
            return authors2024;
    }
}
