import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/index.css';
import {Redirect, Route, Router, Switch} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import ThankYouComponent from "./components/ThankYouComponent";
import AdminComponent from "./components/AdminComponent";
import ModalComponent from "./components/ModalComponent";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import {createGlobalStyle, ThemeProvider} from "styled-components";
import {device, StyledTheme, theme} from "./utils/theme";
import "./fonts/IroSans-Regular.otf"
import "./fonts/IroSans-Light.otf"
import "./fonts/IroSans-Bold.otf"
import "./fonts/IroSans-BoldSlanted.otf"
import "./fonts/IroSans-Slanted.otf"
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export const customHistory = createBrowserHistory();

const fbConfig = {
    apiKey: "AIzaSyB72KWyeBZU-IfOhQ21DLCVVq6lHS6CdwU",
    authDomain: "anasoftliteradb.firebaseapp.com",
    databaseURL: "https://anasoftliteradb.firebaseio.com",
    projectId: "anasoftliteradb",
    storageBucket: "anasoftliteradb.appspot.com",
    messagingSenderId: "771620381025"
};
firebase.initializeApp(fbConfig);
export const database = firebase.database(); //db data
export const auth = firebase.auth(); // initialize auth service


const GlobalStyles = createGlobalStyle`
    html {
        width: 112vw;
        @media ${device.mobileS} {
            width: auto;
        }
    }

    body {
        background-color: ${({theme}) => theme.colors.background};
        color: ${({theme}) => theme.colors.charcoal};
    }
`

ReactDOM.render(
    <ThemeProvider theme={StyledTheme}>
        <GlobalStyles/>
        <MuiThemeProvider muiTheme={theme}>
            <Router history={customHistory}>
                <Switch>
                    <Route exact path={process.env.PUBLIC_URL + "/"} component={App}/>
                    <Route path={process.env.PUBLIC_URL + "/hlasovanie"} component={ModalComponent}/>
                    <Route path={process.env.PUBLIC_URL + "/thanks"} component={ThankYouComponent}/>
                    <Route path={process.env.PUBLIC_URL + "/admin"} component={AdminComponent}/>
                    <Redirect to={process.env.PUBLIC_URL + "/"}/>
                </Switch>
            </Router>
        </MuiThemeProvider>
    </ThemeProvider>,
    document.getElementById('root')
);
