/**
 * Created by Filip Drgoň on 13/04/17.
 */
import React, {Component} from "react";
import Modal from "react-bootstrap-modal";
import isEmail from "validator/lib/isEmail";
import Recaptcha from "react-gcaptcha";
import Checkbox from "material-ui/Checkbox";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";

import {auth, database} from "../index";
import {authors2024} from "../utils/authors";
import {size} from "../utils/theme";
import {StyledHref} from "./common/Common";

export default class ModalComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            preselected: 1,
            name: "",
            surname: "",
            email: "",
            newsletter: false,
            gdpr: false,
            gdprRequired: false,
            validCaptcha: false,
            validEmail: true,
            duplicate: false,
            empty: false,
            largerThanMobileS: false,
        };
        this.apiKey = "6LdVOhwUAAAAAMd3O10SaAuBfUJ20-cVgbe2de_c"; // Captcha Key
    }

    handler = e => {
        this.setState({largerThanMobileS: !!e.matches});
    };

    componentDidMount() {
        const {choice} = this.props;
        choice && this.setState({preselected: choice});
        window.matchMedia(`(min-width: ${size.mobileM})`).addEventListener("change", this.handler);
    }

    componentWillUnmount() {
        window.matchMedia(`(min-width: ${size.mobileM})`).removeEventListener("change", this.handler);
    }

    componentWillReceiveProps(newProps) {
        const {choice} = newProps;
        choice && this.setState({preselected: choice});
    }

    async sendToServer() {
        let {name, surname, email, newsletter, preselected: selected, gdpr} = this.state;
        let date = new Date();
        let dateString = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + ' ' +
            date.getHours() + ':' + date.getMinutes() + ":" + date.getSeconds();
        const payload = {
            name,
            surname,
            email,
            newsletter,
            selected,
            gdpr,
            time: dateString,
        };
        try {
            await auth.signInWithEmailAndPassword("user@litera.sk", "hlasovanie")
        } catch (e) {
            console.log(e.message);
        }
        if (!gdpr) {
            this.setState({gdprRequired: true})
        } else {
            let ref = database.ref('voters2024');
            let key = email.replaceAll('.', '_')
            await ref.child(key).set(payload, (err) => {
                if (err) {
                    this.setState({duplicate: true});
                } else {
                    this.props.parentHandler && this.props.parentHandler();
                    this.props.history.push(process.env.PUBLIC_URL + '/thanks');
                }
            });
        }
    }

    render() {
        const {open = true} = this.props;
        return (
            <Modal show={open}
                   onHide={() => this.props.parentHandler && this.props.parentHandler()}>
                <Modal.Header closeButton>
                    <Modal.Title>Cena čitateľov Anasoft litera 2024</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TextField fullWidth={true}
                               underlineStyle={{borderColor: "#1d0064"}}
                               className="text-field"
                               hintText="Peter"
                               floatingLabelText="Meno"
                               onChange={(e, v) => this.setState({name: v, empty: false, gdprRequired: false})}
                    /><br/>
                    <TextField fullWidth={true}
                               underlineStyle={{borderColor: "#1d0064"}}
                               className="text-field"
                               hintText="Gamboš"
                               floatingLabelText="Priezvisko"
                               onChange={(e, v) => this.setState({surname: v, empty: false, gdprRequired: false})}
                    /><br/>
                    <TextField fullWidth={true}
                               underlineStyle={{borderColor: "#1d0064"}}
                               className="text-field"
                               hintText="peter.gambos@gmail.com"
                               floatingLabelText="E-mail"
                               onChange={(e, v) =>
                                   this.setState({
                                       email: v,
                                       validEmail: isEmail(v),
                                       duplicate: false,
                                       empty: false,
                                       gdprRequired: false
                                   })}
                               type="email"
                               errorText={this.state.validEmail ? null : "Wrong E-mail!"}
                    /><br/>
                    <SelectField
                        underlineStyle={{borderColor: "#1d0064"}}
                        floatingLabelText="Vyberte si knihu"
                        value={this.state.preselected}
                        onChange={(e, i, v) => this.setState({preselected: v})}
                        style={{width: "100%"}}
                        iconStyle={{fill: "black"}}
                        selectedMenuItemStyle={{color: "#1d0064"}}
                    >
                        {
                            authors2024.map((author, index) =>
                                <MenuItem
                                    key={index}
                                    value={index + 1}
                                    primaryText={`${author.name}: ${author.book}`}
                                />
                            )
                        }
                    </SelectField>
                    <Checkbox
                        label="Hlasovaním v Cene čitateľov Anasoft litera súhlasím s poskytnutím a spracovaním
                            uvedených osobných údajov.*"
                        style={{width: "100%", marginTop: "15px"}}
                        onCheck={() => this.setState(prevState => ({
                            gdpr: !prevState.gdpr,
                            gdprRequired: false,
                            empty: false
                        }))}
                        labelStyle={{fontSize: 13, color: this.state.gdprRequired ? "red" : "inherit"}}

                    />
                    <Checkbox
                        label="Chcem dostávať novinky o literárnej cene Anasoft litera.*"
                        style={{width: "100%", marginTop: "15px"}}
                        onCheck={() => this.setState(prevState => ({newsletter: !prevState.newsletter}))}
                        labelStyle={{fontSize: 13}}
                    />
                    <div className="captcha">
                        <Recaptcha
                            sitekey={this.apiKey}
                            size={this.state.largerThanMobileS ? "normal" : "compact"}
                            verifyCallback={() => this.setState({validCaptcha: true})}
                        />
                    </div>
                    <div className="disabled-div">
                        <RaisedButton
                            label="Hlasovať"
                            primary
                            disabled={!this.state.validCaptcha || !this.state.validEmail || this.state.duplicate || this.state.empty || this.state.gdprRequired}
                            onClick={() => this.sendToServer()}
                        />
                        {
                            this.state.duplicate &&
                            <span className="error">
                                    Vami zadaný e-mail je už použitý!
                                    </span>
                        }
                        {
                            this.state.empty &&
                            <span className="error">
                                        Všetky polia sú povinné!
                                    </span>
                        }
                        {
                            this.state.gdprRequired &&
                            <span className="error">
                                        Chýba súhlas so spracovaním osobných údajov!
                                    </span>
                        }
                    </div>
                    <i style={{fontSize: "8pt", fontWeight: "normal"}}>* Hlasovaním v Cene čitateľov Anasoft litera
                        súhlasím s poskytnutím a spracovaním uvedených osobných údajov na účely definované v článku
                        1.2 dokumentu: Informácie pre dotknuté osoby GDPR, zverejnenom na webstránke ceny Anasoft
                        litera <StyledHref target="_blank" rel="noopener noreferrer"
                                           href="https://www.anasoftlitera.sk/home/ochrana-sukromia">tu</StyledHref>.</i>
                </Modal.Body>
            </Modal>
        )
    }
};
