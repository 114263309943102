/**
 * Created by Filip Drgoň on 16/04/17.
 */
import React, {useEffect, useState} from 'react';
import {database} from "../index";

const ThankYouComponent = () => {
    const [endDate, setEndDate] = useState(null)
    const getEndDate = () => {
        let dateRef = database.ref('settings/endDate');
        dateRef.once('value', (snapshot) => {
            if (snapshot.exists()) {
                setEndDate(new Date(snapshot.val()))
            } else {
                console.error('Voting end date not specified');
            }
        }, (err) => {
            console.error(err.message);
        });
    }
    useEffect(() => {
        getEndDate()
    }, [])
    return (
        <div className="root thankyou">
            <div className="thankyou-overhead">
                <div className="thankyou-block">
                    <i className="fa fa-check" aria-hidden="true"/>
                    <div className="title">
                        Úspech!
                    </div>
                    <div className="text">
                        <b>Ďakujeme</b> za Váš hlas a prajeme veľa šťastia v súťaži. Zatiaľ čítajte tú najlepšiu
                        slovenskú prózu.
                        <br/>
                        Vyžrebovaného víťaza alebo víťazku budeme informovať e&#8209;mailom.
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ThankYouComponent;
