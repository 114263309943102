import React, {Component} from 'react';
import 'react-bootstrap-modal/lib/css/rbm-complete.css';
import './styles/App.css';
import ScrollToTop from 'react-scroll-up';
import styled from "styled-components";

import {database} from "./index";
import ModalComponent from "./components/ModalComponent";
import {StyledHref} from "./components/common/Common";
import LogoImage from "./img/logo_AL_2024.png";
import IconImage from "./img/Atomium.png";
import PlanetImage from "./img/Velka_planeta.png";
import PlanetHorizontalImage from "./img/Velka_planeta_horizontal.png";
import OrbitImage from "./img/Obezna_draha.png";
import TypographyImage from "./img/Typografia.png";
import {device} from './utils/theme';
import BooksComponent from "./components/books/BooksComponent";
import {format} from "date-fns";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            choice: 1,
            endDate: null,
        };
    }

    componentDidMount() {
        this.getEndDate();
    }

    getEndDate = () => {
        let dateRef = database.ref('settings/endDate');
        dateRef.once('value', (snapshot) => {
            if (snapshot.exists()) {
                this.setState({endDate: new Date(snapshot.val())})
            } else {
                console.error('Voting end date not specified');
            }
        }, (err) => {
            console.error(err.message);
        });
    }

    render() {
        return (
            <div className="root">
                <StyledTopPlanet src={PlanetHorizontalImage}/>
                <StyledLogo src={LogoImage}/>
                <StyledAtomium src={IconImage}/>
                <StyledPlanet src={PlanetImage}/>
                <ModalComponent
                    parentHandler={() => this.setState({open: false})}
                    open={this.state.open}
                    choice={this.state.choice}
                    history={this.props.history}
                />
                <StyledTopOrbit src={OrbitImage}/>
                <StyledTopTypography src={TypographyImage}/>
                <OrderingWrapper>
                    {this.state.endDate ? this.state.endDate.getTime() < new Date().getTime() ?
                            <div className="books finished">
                                <div className="firstText">
                                    Ďakujeme za vaše hlasy.
                                </div>
                                <div className="secondText">
                                    Hlasovanie Ceny čitateľov Anasoft litera 2024 bolo ukončené.
                                </div>
                            </div>
                            :
                            <BooksComponent onClick={(choice) => this.setState({open: true, choice})}/>
                        :
                        <SpinnerWrapper>
                            <i className="fa fa-spin fa-spinner"/>
                        </SpinnerWrapper>

                    }
                </OrderingWrapper>
                <StyledTypography src={TypographyImage}/>
                <StyledOrbit src={OrbitImage}/>
                <Footer>
                    <h3><b>Anasoft litera</b></h3>
                    <FooterText>
                        Anasoft litera je najlepšie ohodnotená slovenská literárna cena za pôvodnú prózu vydanú v
                        uplynulom roku, ktorá kladie dôraz nielen na finančnú odmenu pre autora najlepšej knihy (10
                        000 eur), ale predovšetkým na propagáciu desiatich kníh, ktoré porota vyhlásila 8. apríla
                        tohto roka. Výsadou Anasoft litery je predovšetkým automatická nominácia všetkých
                        slovenských prozaických titulov a každoročná zmena{" "}
                        <StyledHref href="https://www.anasoftlitera.sk/main/anasoft-litera/porota"
                                    target="_blank">poroty</StyledHref>.
                        <br/>
                        <br/>
                        Ocenenie vzniklo v roku 2006 a{" "}
                        <StyledHref href="https://www.anasoftlitera.sk/main/anasoft-litera/oceneni" target="_blank">
                            doteraz ho získali</StyledHref>:
                        Pavel Vilikovský (dvakrát), Marek Vadas, Milan Zelinka, Alta Vášová, Stanislav Rakús,
                        Monika Kompaníková, Balla, Víťo Staviarsky, Veronika Šikulová, Peter Macsovszky, Ondrej
                        Štefánik, Etela Farkašová, Ivan Medeši, Alena Sabuchová, Barbora Hrínová, Stanislav
                        Rakús a naposledy Soňa Uriková.
                        <br/>
                        Vďaka čitateľskému hlasovaniu doposiaľ Cenu čitateľov{" "}
                        <StyledHref href="https://www.anasoftlitera.sk/main/anasoft-litera/oceneni"
                                    target="_blank">získali</StyledHref>:
                        Jaroslava Blažková, Dušan Dušek, Viliam Klimáček, Pavol Rankov, Veronika Šikulová, Pavol
                        Vilikovský, Veronika Šikulová, Lucia Piussi, Tomáš Varga, Peter Balko, znova Dušan Dušek,
                        Jozef Karika, Daniel Majling, Milo Janáč, Silvester Lavrík, opäť Veronika Šikulová,
                        Lukáš Onderčanin a naposledy Dušo Martinčok.
                        <br/>
                        <br/>
                        <b>Cena čitateľov a čitateliek</b> je literárna cena, o ktorej rozhoduje verejnosť v
                        internetovom
                        hlasovaní.
                        <br/>
                        Hlasovať je možné za prozaické tituly nominované v širšom výbere daného ročníka ceny Anasoft
                        litera.
                        <br/>
                        Hlasujúci čitatelia a čitateľky vstupujú do súťaže o ceny špecifické pre daný ročník.
                        Tento rok sú to dve vstupenky na ceremoniál odovzdávania ceny Anasoft litera.
                        <br/>
                        Internetové hlasovanie je otvorené krátko pred festivalom litera fest (17. - 20. 6. 2024),
                        ktorý je spojený s daným ročníkom ceny Anasoft litera a uzavreté je
                        <b> {this.state.endDate ? format(this.state.endDate, "dd. MM. yyyy") : ""}</b> o
                        <b> {this.state.endDate ? format(this.state.endDate, "HH:mm") : ""}</b>,
                        teda maximálne 3 dni pred ceremoniálom ceny Anasoft litera, ktorý sa uskutoční v polovici
                        októbra 2024.
                        Víťaz alebo víťazka je spomedzi hlasujúcich vyžrebovaný/á organizátormi ceny deň po uzavretí
                        hlasovania. Kontaktovaný/á bude e-mailom uvedeným pri hlasovaní.
                    </FooterText>
                </Footer>
                <ScrollToTop showUnder={200} duration={1000}>
                    <ArrowUp className="fa fa-arrow-circle-up"/>
                </ScrollToTop>
            </div>
        )
    }
}

export default App;


const Footer = styled.footer`
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    margin-top: 20px;
    background-color: ${({theme}) => theme.colors.altBackground};
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: ${({theme}) => theme.colors.white};
    z-index: 1;

    a {
        color: inherit;
    }
`

const FooterText = styled.div`
    text-align: left;
    color: ${({theme}) => theme.colors.white}
`

const ArrowUp = styled.i`
    font-size: 48px;
    color: ${({theme}) => theme.colors.white}

`

const StyledLogo = styled.img`
    width: 100px;
    height: auto;
    display: block;
    position: relative;
    margin-top: 32px;
    @media ${device.tablet} {
        margin-top: 0;
        top: 32px;
        right: 32px;
        position: absolute;
        flex-direction: column;
        display: block;
    }
`

const StyledAtomium = styled.img`
    position: absolute;
    top: 32px;
    left: 32px;
    width: 64px;
    height: auto;
    display: none;
    @media ${device.tablet} {
        display: block;
    }
`

const StyledPlanet = styled.img`
    position: absolute;
    top: 128px;
    left: 0px;
    width: 192px;
    height: auto;
    display: none;
    @media ${device.tablet} {
        display: block;
    }
`

const StyledOrbit = styled.img`
    height: auto;
    width: 100vw;
    margin-top: 32px;
    display: none;
    position: relative;
    @media ${device.laptop} {
        display: block;
    }
`

const StyledTopTypography = styled.img`
    width: 100%;
    height: auto;
    display: block;
    margin-top: 16px;
    @media ${device.tablet} {
        display: none
    }
`

const StyledTopOrbit = styled.img`
    width: 100%;
    height: auto;
    display: block;
    margin-top: 32px;
    @media ${device.tablet} {
        display: none
    }
`

const StyledTopPlanet = styled.img`
    width: 100%;
    height: auto;
    display: block;
    @media ${device.tablet} {
        display: none
    }
`

const StyledTypography = styled.img`
    height: auto;
    margin: 32px;
    display: none;
    z-index: 1;
    @media ${device.tablet} {
        display: block;
        width: 720px;
    }
    @media ${device.laptop} {
        width: 1024px;
    }
    @media ${device.laptopL} {
        width: 1440px;
    }
`


const OrderingWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    @media ${device.tablet} {
        flex-direction: column;
    }
`

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
`
