import React from "react";
import styled, {useTheme} from "styled-components";
import {authors2024} from "../../utils/authors";
import {device, size} from "../../utils/theme";


const BooksComponent = ({onClick}) => {
    const extendedAuthors2024 = useGenerateExtendedAuthors2024(authors2024)
    return (
        <BooksComponentWrapper>
            {extendedAuthors2024.map((author, idx) => {
                const [name, surname] = author.name.split(' ')
                return (
                    <BookItemWrapper backgroundColor={author.backgroundColor}
                                     key={idx}
                                     onClick={() => author.id && onClick(author.id)}
                    >
                        {author.picture &&
                            <BookImage src={author.picture}/>
                        }
                        <BookDescription>
                            <BookBadges>
                                <ColorBadge color={author.backgroundColor}/>
                                <InitialBadge>{surname[0]}</InitialBadge>
                            </BookBadges>
                            <Divider/>
                            <BookName>
                                {name}
                            </BookName>
                            <Divider/>
                            <BookSurname>
                                {surname}
                            </BookSurname>
                        </BookDescription>
                        <StyledUnderscore/>
                    </BookItemWrapper>
                )
            })}
        </BooksComponentWrapper>
    );
};


export default BooksComponent;


const useGenerateExtendedAuthors2024 = (authors) => {
    const theme = useTheme()
    return [
        {
            ...authors[0],
            backgroundColor: theme.colors.first2024
        },
        {
            ...authors[1],
            backgroundColor: theme.colors.second2024
        },
        {
            ...authors[2],
            backgroundColor: theme.colors.third2024
        },
        {
            ...authors[3],
            backgroundColor: theme.colors.fourth2024
        },
        {
            ...authors[4],
            backgroundColor: theme.colors.fifth2024
        },
        {
            ...authors[5],
            backgroundColor: theme.colors.sixth2024
        },
        {
            ...authors[6],
            backgroundColor: theme.colors.seventh2024
        },
        {
            ...authors[7],
            backgroundColor: theme.colors.eighth2024
        },
        {
            ...authors[8],
            backgroundColor: theme.colors.ninth2024
        },
        {
            ...authors[9],
            backgroundColor: theme.colors.tenth2024
        }
    ]
}


const BooksComponentWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    width: min-content;
    margin-top: 16px;
    @media ${device.laptop} {
        margin-top: 128px;
        grid-template-columns: repeat(5, 1fr);
    }

`


const BookImage = styled.img`
    height: 50%;
    width: auto;
    transition: transform ease-in-out 300ms;
`

const BookItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 250px;
    width: 200px;
    cursor: pointer;

    @media ${device.laptopL} {
        height: 350px;
        width: 250px;
    }

    &:hover ${BookImage} {
        transform: rotate(35deg);
    }

    @media screen and (max-width: ${size.laptop}) {

        &:not(:nth-child(2n))::after {
            content: '';
            background-color: white;
            height: 100%;
            width: 2px;
            position: absolute;
            right: 0;
            transform: translateX(4px);
        }
    }
    @media ${device.laptop} {
        &:not(:nth-child(5n))::after {
            content: '';
            background-color: white;
            height: 100%;
            width: 2px;
            position: absolute;
            right: 0;
            transform: translateX(4px);
        }
    }
`

const BookDescription = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: -webkit-fill-available;
    margin-left: 48px;
    margin-right: 32px;
    gap: 4px;
`

const BookBadges = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    margin-left: -28px; // 24 (badge size) + 4 (half of gap)
    margin-bottom: 2px;
`

const ColorBadge = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: ${({color}) => color};
`

const InitialBadge = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
`

const BookName = styled.span`
    display: flex;
    color: white;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 16px;
    @media ${device.laptopL} {
        font-size: 24px;
        line-height: 24px;
    }
`

const BookSurname = styled.span`
    display: flex;
    color: white;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 16px;
    @media ${device.laptopL} {
        font-size: 24px;
        line-height: 24px;
    }
`

const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: white;
`

const StyledUnderscore = styled.div`
    position: absolute;
    bottom: 0;
    left: 16px;
    width: 30px;
    height: 2px;
    background-color: white;
`
