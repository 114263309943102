/**
 * Created by Filip Drgoň on 22/04/2018.
 */
//2024
import babicka from '../img/babicka.png'
import bodZlomu from '../img/bod_zlomu.png'
import detiVDazdi from '../img/deti_v_dazdi.png'
import druhyZosit from '../img/druhy_cierny_zosit.png'
import hotelBalkan from '../img/hotel_balkan.png'
import modroslepost from '../img/modroslepost.png'
import siroty from '../img/siroty.png'
import spomenTrencin from '../img/spomenies_si_na_trencin.png'
import storocie from '../img/storocie.png'
import vivat_akademia from '../img/vivat_akademia.png'

//Legacy
import cabala from '../img/satori_v_trencine.png'
import farkasova from '../img/zachrana_sveta.png';
import gibova from '../img/eklektik_bastard.png';
import hrinova from '../img/jednorozce.png';
import juranova from '../img/nanic_hodnica.png';
import kerata from '../img/na_okraji_mojej_hory.png';
import pupala from '../img/zeny_aj_muzi.png';
import sikulova from '../img/tremolo_ostinato.png';
import smatlakova from '../img/nic_sa_nestalo.png';
import vasova from '../img/odlety.png';
import balla from '../img/medzi_ruinami.png.jpg';
import vadas from '../img/sest_cudzincov.jpg';
import dobrakova from '../img/pod_slnkom_turina.jpg'
import hochholczerova from '../img/izba_sa_neda_zjest.jpg'
import medesi from '../img/vilkovia.jpg'
import micenkova from '../img/krv_je_voda.jpg'
import ondercanin from '../img/v_leninovej_zahrade.jpg'
import rakus from '../img/lutostivost.jpg'
import rozembergova from '../img/zjedol_som_lautreca.jpg'
import jelen from '../img/dom_pre_jelena.jpg'
import radost from '../img/dovod_na_radost.jpg'
import matky from '../img/krajina_matiek.jpg'
import listicky from '../img/listicky_maju_rady_teplo.jpg'
import mafiani from '../img/mafianske_balady.jpg'
import spaci from '../img/nepokojni_spaci.jpg'
import niekto from '../img/niekto_sa_najde.jpg'
import petchwork from '../img/petchwork_v_bielej.jpg'
import funus from '../img/po_funuse.jpg'
import ps from '../img/ps.jpg'

export const authors2024 = [
    {
        id: 1,
        name: "Lukáš Cabala",
        book: "Spomenieš si na Trenčín?",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/cabala-lukas/spomenies-si-na-trencin",
        publisher: "Artforum, 2023",
        picture: spomenTrencin
    },
    {
        id: 2,
        name: "Dušan Dušek",
        book: "Deti v daždi",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/dusek-dusan/deti-v-dazdi",
        publisher: "Slovart, 2023",
        picture: detiVDazdi
    },
    {
        id: 3,
        name: "Ivana Gibová",
        book: "Babička©",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/gibova-ivana/babicka%C2%A9",
        publisher: "Vlna, 2023",
        picture: babicka
    },
    {
        id: 4,
        name: "Juraj Kováčik",
        book: "Bod zlomu",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/kovacik-juraj/bod-zlomu",
        publisher: "Slovart, 2023",
        picture: bodZlomu
    },
    {
        id: 5,
        name: "Katarína Kucbelová",
        book: "Modrosleposť",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/kucbelova-katarina/modroslepost",
        publisher: "Slovart, 2023",
        picture: modroslepost
    },
    {
        id: 6,
        name: "Miroslava Kuľková",
        book: "Hotel Balkán",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/kulkova-miroslva/hotel-balkan",
        publisher: "KK Bagala, 2023",
        picture: hotelBalkan
    },
    {
        id: 7,
        name: "Richard Pupala",
        book: "Druhý čierny zošit",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/pupala-richard/druhy-cierny-zosit",
        publisher: "Lindeni, 2023",
        picture: druhyZosit
    },
    {
        id: 8,
        name: "Jozef Puškáš",
        book: "Vivat, akadémia, zbohom, Čechov",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/puskas-jozef/vivat-akademia-zbohom-cechov",
        publisher: "Orman, 2023",
        picture: vivat_akademia
    },
    {
        id: 9,
        name: "Vanda Rozenbergová",
        book: "Storočie",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/rozenbergova-vanda/storocie",
        publisher: "Slovart, 2023",
        picture: storocie
    },
    {
        id: 10,
        name: "Ondrej Štefánik",
        book: "Siroty",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/stefanik-ondrej/siroty",
        publisher: "Tatran, 2023",
        picture: siroty
    }
]

export const authors2023 = [
    {
        id: 1,
        name: "Bodnárová, Jana",
        book: "Patchwork v bielej",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/bodnarova-jana/petchwork-v-bielej",
        publisher: "Aspekt, 2022",
        picture: petchwork
    },
    {
        id: 2,
        name: "Juhás, Jakub",
        book: "PS",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/juhasz-jakub/ps",
        publisher: "RUBATO, 2022",
        picture: ps
    },
    {
        id: 3,
        name: "Liptáková, Slavka",
        book: "Krajina matiek",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/liptakova-slavka/krajina-matiek",
        publisher: "Petrus, 2022",
        picture: matky
    },
    {
        id: 4,
        name: "Martinčok, Dušo",
        book: "Niekto sa nájde",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/martincok-duso/niekto-sa-najde",
        publisher: "Zrejme, 2022",
        picture: niekto
    },
    {
        id: 5,
        name: "Moravčíková, Dominika",
        book: "Dom pre jeleňa",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/moravcikova-dominika/dom-pre-jelena",
        publisher: "KK Bagala, 2022",
        picture: jelen
    },
    {
        id: 6,
        name: "Rosová, Michaela",
        book: "Nepokojní spáči",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/rosova-michaela/nepokojni-spaci",
        publisher: "Artforum, 2022",
        picture: spaci
    },
    {
        id: 7,
        name: "Spevák, Jakub",
        book: "Po funuse",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/spevak-jakub/po-funuse",
        publisher: "KK Bagala, 2022",
        picture: funus
    },
    {
        id: 8,
        name: "Šikulová, Veronika",
        book: "Líštičky majú rady teplo",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/sikulova-veronika/listicky-maju-rady-teplo",
        publisher: "Vydavateľstvo SLOVART, 2022",
        picture: listicky
    },
    {
        id: 9,
        name: "Taragel, Dušan",
        book: "Mafiánske balady",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/taragel-dusan/mafianske-balady",
        publisher: "Vydavateľstvo SLOVART, 2022",
        picture: mafiani
    },
    {
        id: 10,
        name: "Uriková, Soňa",
        book: "Dôvod na radosť",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/urikova-sona/dovod-na-radost",
        publisher: "KK Bagala, 2022",
        picture: radost
    }
]

export const authors2022 = [
    {
        id: 1,
        name: "Balla",
        book: "Medzi ruinami",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/balla/medzi-ruinami",
        publisher: "(KK Bagala, 2021)",
        picture: balla
    },
    {
        id: 2,
        name: "Dobrakovová, Ivana",
        book: "Pod slnkom Turína",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/dobrakovova-ivana/pod-slnkom-turina",
        publisher: "(Marenčin PT, 2021)",
        picture: dobrakova
    },
    {
        id: 3,
        name: "Hochholczerová, Nicol",
        book: "Táto izba sa nedá zjesť",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/nicol-hochholczerova/tato-izba-sa-neda-zjest",
        publisher: "(KK Bagala, 2021)",
        picture: hochholczerova
    },
    {
        id: 4,
        name: "Medeši, Ivan",
        book: "Vilkovia",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/medesi-ivan/vilkovia",
        publisher: "(Východoslovenské združenie Valal, 2021)",
        picture: medesi
    },
    {
        id: 5,
        name: "Micenková, Jana",
        book: "Krv je len voda",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/jana-micenkova/krv-je-len-voda",
        publisher: "(Marenčin PT, 2021)",
        picture: micenkova
    },
    {
        id: 6,
        name: "Onderčanin, Lukáš",
        book: "Utópia v Leninovej záhrade",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/ondercanin-lukas/utopia-v-leninovej-zahrade",
        publisher: "(Vydavateľstvo Absynt, 2021)",
        picture: ondercanin
    },
    {
        id: 7,
        name: "Rakús, Stanislav",
        book: "Ľútostivosť",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/rakus-stanislav/lutostivost",
        publisher: "(KK Bagala, 2021)",
        picture: rakus
    },
    {
        id: 8,
        name: "Rozenbergová, Vanda",
        book: "Zjedol som Lautreca",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/rozenbergova-vanda/zjedol-som-lautreca",
        publisher: "(Vydavateľstvo SLOVART, 2021)",
        picture: rozembergova
    },
    // {
    //     id: 9,
    //     name: "Soltész, Arpád",
    //     book: "Zlodej",
    //     href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/soltesz-arpad/zlodej",
    //     publisher: "(Ikar, 2021)",
    //     picture: soltesz
    // },
    {
        id: 10,
        name: "Vadas, Marek",
        book: "Šesť cudzincov",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/vadas-marek/sest-cudzincov",
        publisher: "(KK Bagala, 2021)",
        picture: vadas
    }
]

export const authors2021 = [
    {
        id: 1,
        name: "Lukáš Cabala",
        book: "Satori v Trenčíne",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/cabala-lukas/",
        publisher: "(Vydavateľstvo Artforum, 2020)",
        picture: cabala,
    },
    {
        id: 2,
        name: "Etela Farkašová",
        book: "Záchrana sveta podľa G.",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/farkasova-etela/",
        publisher: "(Vydavateľstvo Spolku slovenských spisovateľov, 2020)",
        picture: farkasova,
    },
    {
        id: 3,
        name: "Ivana Gibová",
        book: "Eklektik Bastard",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/gibova-ivana/",
        publisher: "(Drewo a srd, 2020)",
        picture: gibova,
    },
    {
        id: 4,
        name: "Barbora Hrínová",
        book: "Jednorožce",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/hrinova-barbora/",
        publisher: "(Aspekt, 2020)",
        picture: hrinova,
    },
    {
        id: 5,
        name: "Jana Juráňová",
        book: "Naničhodnica",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/juranova-jana/",
        publisher: "(Aspekt, 2020)",
        picture: juranova,
    },
    {
        id: 6,
        name: "Laco Kerata",
        book: "Na okraji mojej hory",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/kerata-laco/",
        publisher: "(KK Bagala, 2020)",
        picture: kerata,
    },
    {
        id: 7,
        name: "Richard Pupala",
        book: "Ženy aj muži, zvieratá",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/pupala-richard/",
        publisher: "(Lindeni, 2020)",
        picture: pupala,
    },
    {
        id: 8,
        name: "Veronika Šikulová",
        book: "Tremolo ostinato",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/sikulova-veronika/",
        publisher: "(Vydavateľstvo SLOVART, 2020)",
        picture: sikulova,
    },
    {
        id: 9,
        name: "Zuzana Šmatláková",
        book: "Nič sa nestalo",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/smatlakova-zuzana/",
        publisher: "(Marenčin PT, 2019)",
        picture: smatlakova,
    },
    {
        id: 10,
        name: "Alta Vášová",
        book: "Odlety",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/vasova-alta/",
        publisher: "(F. R. & G., 2019)",
        picture: vasova,
    }
]

export const authors2020 = [
    {
        color: "lightPink",
        id: 1,
        short: "Pb",
        name: "Peter Balko",
        book: "Østrov",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/balko-peter/",
        publisher: "(KK Bagala, 2019)",
    },
    {
        color: "darkBlue",
        id: 2,
        short: "Jb",
        name: "Jana Bodnárová",
        book: "Koža",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/bodnarova-jana/",
        publisher: "(Aspekt, 2019)",
    },
    {
        color: "lightPink",
        id: 3,
        short: "Ef",
        name: "Etela Farkašová",
        book: "Hodiny lietania",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/farkasova-etela/",
        publisher: "(Vydavateľstvo Spolku slovenských spisovateľov, 2019)",
    },
    {
        color: "lightYellow",
        id: 4,
        short: "Kk",
        name: "Katarína Kucbelová",
        book: "Čepiec",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/kucbelova-katarina/",
        publisher: "(Slovart, 2019)",
    },
    {
        color: "lightPurple",
        id: 5,
        short: "Sl",
        name: "Silvester Lavrík",
        book: "Posledná k. & k. barónka",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/lavrik-silvester/",
        publisher: "(Dixit, 2019)",
    },
    {
        color: "lightPink",
        id: 6,
        short: "Zm",
        name: "Zuzana Mojžišová",
        book: "Modus vivendi",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/mojzisova-zuzana/",
        publisher: "(Artforum, 2019)",
    },
    {
        color: "lightGray",
        id: 7,
        short: "Mr",
        name: "Michaela Rosová",
        book: "Tvoja izba",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/rosova-michaela/",
        publisher: "(vlastným nákladom, 2019)",
    },
    {
        color: "lightPink",
        id: 8,
        short: "As",
        name: "Alena Sabuchová",
        book: "Šeptuchy",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/sabuchova-alena/",
        publisher: "(Artforum, 2019)",
    },
    {
        color: "lightGreen",
        id: 9,
        short: "Vs",
        name: "Víťo Staviarsky",
        book: "Kšeft",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/staviarsky-vito/",
        publisher: "(Vydavateľstvo Staviarsky, 2019)",
    },
    {
        color: "lightPink",
        id: 10,
        short: "Pš",
        name: "Peter Šulej",
        book: "Fytopaleontológia",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/sulej-peter/",
        publisher: "(Marenčin PT, 2019)",
    }
]

export const authors2019 = [
    {
        name: "Bindzár, Juraj",
        book: "Zvon",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/bindzar-juraj/",
        publisher: "(Orman, 2018)",
    },
    {
        name: "Bodnárová, Jana",
        book: "NOCturná",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/bodnarova-jana/",
        publisher: "(Trio Publishing, 2018)",
    },
    {
        name: "Dobrakovová, Ivana",
        book: "Matky a kamionisti",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/dobrakovova-ivana/",
        publisher: "(Marenčin PT, 2018)",
    },
    {
        name: "Fulmeková, Denisa",
        book: "Doktor Mráz",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/fulmekova-denisa/",
        publisher: "(Slovart, 2018)",
    },
    {
        name: "Janáč, Milo",
        book: "Milo nemilo",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/janac-milo/",
        publisher: "(Kráľovstvo dokonalosti, 2018)",
    },
    {
        name: "Kováčik, Juraj",
        book: "V panoramatickom kine",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/kovacik-juraj/",
        publisher: "(Slovart, 2018)",
    },
    {
        name: "Medeši, Ivan",
        book: "Jedenie",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/medesi-ivan/",
        publisher: "(Východoslovenské združenie VALAL, 2018)",
    },
    {
        name: "Rozenbergová, Vanda",
        book: "Tri smrtky sa plavia",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/rozenbergova-vanda/",
        publisher: "(Slovart, 2018)",
    },
    {
        name: "Vadas, Marek",
        book: "Zlá štvrť",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/vadas-marek/",
        publisher: "(K. K. Bagala, 2018)",
    },
    {
        name: "Vilikovský, Pavel",
        book: "RAJc je preč",
        href: "https://www.anasoftlitera.sk/main/anasoft-litera/autori/vilikovsky-pavel/",
        publisher: "(Petrus, 2018)",
    },
];

export const authors2018 = [
    {
        name: "Etela Farkašová",
        book: "Scenár",
        publisher: "(Vyd. Spolku slovenských spisovateľov, 2017)",
    },
    {
        name: "Peter Macsovszky",
        book: "Povrch vašej planéty",
        publisher: "(Drewo a srd / VLNA, 2017)",
    },
    {
        name: "Daniel Majling",
        book: "Ruzká klazika",
        publisher: "(BRAK, 2017)",
    },
    {
        name: "Jana Micenková",
        book: "Sladký život",
        publisher: "(Marenčin PT, 2017)",
    },
    {
        name: "Mária Modrovich",
        book: "Flešbek",
        publisher: "(Drewo a srd / VLNA, 2017)",
    },
    {
        name: "Richard Pupala",
        book: "Čierny zošit",
        publisher: "(Marenčin PT, Marenčin Media, 2017)",
    },
    {
        name: "Vanda Rozenbergová",
        book: "Muž z jamy a deti z lásky",
        publisher: "(Vydavateľstvo SLOVART, 2017)",
    },
    {
        name: "Alexandra Salmela",
        book: "Antihrdina",
        publisher: "(Vydavateľstvo Artforum, 2017)",
    },
    {
        name: "Dušan Vicen",
        book: "Dotyk s absolútnom",
        publisher: "(ACAPU, 2017)",
    },
    {
        name: "Pavel Vilikovský",
        book: "Krásna strojvodkyňa, krutá vojvodkyňa",
        publisher: "(Vydavateľstvo SLOVART, 2017)",
    },
];
